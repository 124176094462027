import Heading from '@collinsonx/design-system/components/heading/Heading';
import { Button, Center, Flex, Stack } from '@collinsonx/design-system/core';
import { useForm } from '@collinsonx/design-system/form';
import { FlightDetails, LinkedAccount } from '@collinsonx/utils';
import { useLazyQuery, useMutation } from '@collinsonx/utils/apollo';
import linkAccount from '@collinsonx/utils/mutations/linkAccount';
import { getFlightDetails } from '@collinsonx/utils/queries';
import { BookingAmendmentPayInFullInfoMessage } from '@components/booking/BookingAmendmentPayInFullInfoMessage';
import EntitlementsBlock from '@components/EntitlementsBlock/EntitlementsBlock';
import { FlightInfo } from '@components/flightInfo/FlightInfo';
import GuestInfo from '@components/GuestInfo';
import Layout from '@components/Layout';
import { LoungeInfo } from '@components/LoungeInfo';
import TopBarLinks from '@components/TopBarLinks';
import TotalPriceBlock from '@components/TotalPriceBlock/TotalPriceBlock';
import { accountIsEqual, logAction } from '@lib';
import {
  CODE_IATA,
  CODE_ICAO,
  DATE_FORMAT,
  OAG_API_VERSION,
} from 'config/Constants';
import { BookingContext } from 'context/bookingContext';
import { FlightContext } from 'context/flightContext';
import { useError } from 'hooks/error';
import useOutlet from 'hooks/outlet';
import usePayload from 'hooks/payload';
import useLocale from 'hooks/useLocale';
import { debounce } from 'lodash';
import router from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatDate } from 'utils/DateFormatter';

import { ANALYTICS_TAGS, BOOKING_MODE, MAX_GUESTS } from '../../constants';
import { validateFlightNumber } from '../../utils/flightValidation';
import classes from './CheckAvailability.module.css';

type reservationDetails = {
  adults?: number;
  bookingId: string;
  children?: number;
  currentPrice: number | undefined;
  departureDate: string;
  existing_booking_slot?: string;
  flightNumber: string;
  infants?: number;
  price: number;
  reference?: string;
};

interface CheckAvailabilityProps {
  handleClick: () => void;
  mode: BOOKING_MODE;
  pageTitle: string;
  reservationDetails?: reservationDetails;
  trackingPageName: string;
}

const CheckAvailability = ({
  handleClick,
  mode,
  reservationDetails,
  trackingPageName = '',
}: CheckAvailabilityProps) => {
  const [guestError, setGuestError] = useState<boolean>(false);
  const [checkAvailabilityLoading, setCheckAvailabilityLoading] =
    useState<boolean>(false);
  const [loadingEntitlements, setLoadingEntitlements] =
    useState<boolean>(false);
  const { consumerData, jwt, payload, referrerUrl } = usePayload();
  const { lounge } = useOutlet();
  const { booking, setBooking } = useContext(BookingContext);
  const { setFlight } = useContext(FlightContext);
  const { setError } = useError();

  const translations = useLocale();

  // TODO: extract this logic as it's used in multiple locations
  const findLinkedAccount = useCallback(
    (linkedAccounts: LinkedAccount[] = []) =>
      linkedAccounts.find(accountIsEqual(payload)),
    [payload]
  );

  const [dolinkAccount] = useMutation(linkAccount);

  const handleLinkAccount = () =>
    dolinkAccount({
      variables: {
        linkedAccountInput: {
          analytics: { email: consumerData?.getConsumerByID.emailAddress },
          token: jwt,
        },
      },
    });

  useEffect(() => {
    if (consumerData) {
      // TODO: This could be provided by the API hooks - https://lifestyle-x.atlassian.net/browse/BAAS-1487
      const consumer = consumerData.getConsumerByID;
      const { linkedAccounts } = consumer;

      const matchedAccount = findLinkedAccount(linkedAccounts || []);

      if (payload?.membershipType !== matchedAccount?.membershipType) {
        handleLinkAccount();
      }
    }
  }, [consumerData]);

  useEffect(() => {
    if (reservationDetails) {
      form.setValues({
        adults: reservationDetails?.adults || 1,
        children: reservationDetails?.children || 0,
        departureDate: reservationDetails?.departureDate
          ? new Date(String(reservationDetails?.departureDate))
          : null,
        flightNumber: reservationDetails?.flightNumber ?? '',
        infants: reservationDetails?.infants || 0,
      });
    }
  }, [reservationDetails]);

  const form = useForm({
    initialValues: {
      adults: reservationDetails?.adults || booking?.adults || 1,
      amendmentCurrentAttendees: 0,
      bookingId: reservationDetails?.bookingId || '',
      children: reservationDetails?.children || booking?.children || 0,
      currentPrice: reservationDetails?.price,
      departureDate: reservationDetails?.departureDate
        ? new Date(String(reservationDetails?.departureDate))
        : booking?.departureDate
        ? new Date(String(booking?.departureDate))
        : null,
      existing_booking_slot: reservationDetails?.existing_booking_slot || '',
      flightNumber:
        reservationDetails?.flightNumber ?? booking?.flightNumber ?? '',
      infants: reservationDetails?.infants || booking?.infants || 0,
      reference: reservationDetails?.reference ?? '',
    },
    onValuesChange: debounce(
      ({ adults, children, departureDate, flightNumber, infants }) => {
        const reservationGuests =
          (reservationDetails?.adults ?? 0) +
          (reservationDetails?.children ?? 0);
        const priceIsAmended =
          mode === BOOKING_MODE.EDIT &&
          reservationGuests - adults - children !== 0;

        setBooking({
          adults,
          children,
          departureDate,
          flightNumber,
          infants,
          priceIsAmended,
        });
      },
      50
    ),
    transformValues: (values) => ({
      ...values,
      flightNumber: values.flightNumber.toUpperCase(),
    }),
    validate: {
      departureDate: (value) => {
        logAction(
          trackingPageName,
          mode === BOOKING_MODE.CREATE
            ? ANALYTICS_TAGS.ON_CHANGE_DATE_ERROR
            : ANALYTICS_TAGS.ON_CHANGE_DATE_ERROR_EDIT
        );

        return value !== null
          ? null
          : translations.booking.flightDetails.errors.invalid_date;
      },
      flightNumber: (value: string) => {
        let error = null;

        const validFlight =
          /^([A-Za-z]{2}|[A-Za-z]{3}|[A-Za-z][0-9]|[0-9][A-Za-z])([0-9]+[A-Za-z]?)$/.test(
            value.toUpperCase()
          );

        if (!validFlight) {
          error = translations.booking.flightDetails.errors.invalid_flight;
          logAction(
            trackingPageName,
            mode === BOOKING_MODE.CREATE
              ? ANALYTICS_TAGS.ON_CHANGE_FLIGHT_NUMBER_ERROR
              : ANALYTICS_TAGS.ON_CHANGE_FLIGHT_NUMBER_ERROR_EDIT
          );
        }

        return error;
      },
    },
  });

  type FormValues = typeof form.values;

  const [fetchFlightInfo] = useLazyQuery<{
    getFlightDetails: FlightDetails[];
  }>(getFlightDetails, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (flightInfoData) => {
      if (flightInfoData.getFlightDetails.length === 0) {
        form.setFieldError(
          'flightNumber',
          translations.booking.flightDetails.errors.invalid_dateflight
        );
        logAction(
          trackingPageName,
          mode === BOOKING_MODE.CREATE
            ? ANALYTICS_TAGS.ON_CHANGE_FLIGHT_NUMBER_ERROR
            : ANALYTICS_TAGS.ON_CHANGE_FLIGHT_NUMBER_ERROR_EDIT
        );
      } else if (form.isValid()) {
        form.values.flightNumber = form.values.flightNumber.toUpperCase();
        if (mode === BOOKING_MODE.EDIT) {
          form.values.bookingId = reservationDetails?.bookingId ?? '';
        }

        const booking = form.values;
        booking.currentPrice = reservationDetails?.price;
        booking.amendmentCurrentAttendees =
          Number(reservationDetails?.adults) +
          Number(reservationDetails?.children);
        booking.reference = reservationDetails?.reference ?? '';

        setBooking(booking);
        setFlight(flightInfoData.getFlightDetails[0]);
        const { query } = router;
        router.push({
          pathname: '/check-slots',
          query: {
            ...query,
          },
        });
      }
      setCheckAvailabilityLoading(false);
    },
    onError: (error) => {
      setError({
        error,
        isError: true,
        message: translations.generic.error.loungeNoFound,
      });
      setCheckAvailabilityLoading(false);
    },
    pollInterval: 300000,
  });

  const handleClickCheckAvailability = async (values: FormValues) => {
    logAction(
      trackingPageName,
      mode === BOOKING_MODE.CREATE
        ? ANALYTICS_TAGS.ON_CONTINUE_BUTTON_AVI
        : ANALYTICS_TAGS.ON_CONTINUE_BUTTON_AVI_EDIT
    );
    if (values.children + values.adults > MAX_GUESTS) {
      setGuestError(true);
      logAction(
        trackingPageName,
        mode === BOOKING_MODE.CREATE
          ? ANALYTICS_TAGS.ON_CHANGE_ERROR_ATTENDEES_AVL
          : ANALYTICS_TAGS.ON_CHANGE_ERROR_ATTENDEES_AVL_EDIT
      );
      return false;
    }
    setGuestError(false);

    if (form.isValid()) {
      setCheckAvailabilityLoading(true);
      const upperCaseFlight = form.values.flightNumber.toUpperCase();
      const carrieCode = validateFlightNumber(upperCaseFlight)[1];
      const flightNo = validateFlightNumber(upperCaseFlight)[2];

      fetchFlightInfo({
        variables: {
          flightDetails: {
            carrierCode: carrieCode,
            codeType: `${CODE_IATA}, ${CODE_ICAO}`,
            departureDate: formatDate(
              new Date(String(form.values.departureDate)),
              DATE_FORMAT
            ),
            flightNumber: flightNo,
            version: OAG_API_VERSION,
          },
        },
      });

      values.flightNumber = upperCaseFlight;
    }

    setBooking(values);
    handleClick();
  };

  return (
    <Layout>
      <form onSubmit={form.onSubmit(handleClickCheckAvailability)}>
        <Stack gap={8}>
          <Stack w="100%">
            <TopBarLinks page={trackingPageName} />
          </Stack>
          <Flex align="center" className={classes.container}>
            <Stack className={classes.stack} gap={12}>
              {' '}
              <Center className={classes.titleWrapper}>
                <Heading as="h1" lineHeight={1} margin={0} padding={0}>
                  {mode === BOOKING_MODE.EDIT
                    ? translations.booking.flightAndGuests.amendTitle
                    : translations.booking.flightAndGuests.title}
                </Heading>
              </Center>
              <LoungeInfo loading={!lounge} lounge={lounge} />
              <FlightInfo
                form={form}
                loading={!lounge}
                page={trackingPageName}
                tags={[
                  mode === BOOKING_MODE.CREATE
                    ? ANALYTICS_TAGS.ON_CHANGE_DATE
                    : ANALYTICS_TAGS.ON_CHANGE_DATE_EDIT,
                  mode === BOOKING_MODE.CREATE
                    ? ANALYTICS_TAGS.ON_CHANGE_FLIGHT_NUMBER
                    : ANALYTICS_TAGS.ON_CHANGE_FLIGHT_NUMBER_EDIT,
                ]}
              />
              <GuestInfo
                form={form}
                guestError={guestError}
                loading={!lounge}
                referreUrl={referrerUrl ?? '#'}
              />
              {mode === BOOKING_MODE.CREATE && booking.departureDate && (
                <EntitlementsBlock setLoading={setLoadingEntitlements} />
              )}
              {mode === BOOKING_MODE.EDIT && booking.priceIsAmended && (
                <>
                  <TotalPriceBlock />
                  <BookingAmendmentPayInFullInfoMessage />
                </>
              )}
              {mode === BOOKING_MODE.CREATE && <TotalPriceBlock />}
              <Center w="100%">
                <Button
                  disabled={
                    !lounge || loadingEntitlements || checkAvailabilityLoading
                  }
                  type="submit"
                >
                  {translations.booking.checkAvailability.btn}
                </Button>
              </Center>
            </Stack>
          </Flex>
        </Stack>
      </form>
    </Layout>
  );
};

export default CheckAvailability;
